<template>
  <div id="audit">
    <a-tabs @change="tabChangeCallback" type="card">
      <a-tab-pane tab="申请列表" key="1"></a-tab-pane>
      <a-tab-pane tab="审核记录" key="2"></a-tab-pane>
    </a-tabs>
    <div class="tab-content">
      <a-form
        class="filter-condition-form"
        layout="inline"
        :form="form"
        @submit="handleFormSubmit"
        v-auth="'user_manage_audit_list'"
      >
        <a-form-item>
          <a-input
            class="keyword-input"
            v-decorator="['mdtz']"
            placeholder="手机号/抖音号/主播昵称"
            allowClear
            @change="handleFormInputChange"
          />
        </a-form-item>
       <!-- <a-form-item>
          <a-select style="width: 120px" placeholder="渠道筛选" v-decorator="['channel']" allowClear>
            <a-select-option value="1">抖音</a-select-option>
            <a-select-option value="2">淘宝</a-select-option>
          </a-select>
        </a-form-item>-->
        <a-form-item>
          <a-select
            style="width: 120px"
            placeholder="审核状态"
            v-if="currentTab==='2'"
            v-decorator="['status']"
            allowClear
          >
            <!-- <a-select-option value="0">审核中</a-select-option> -->
            <a-select-option value="1">审核通过</a-select-option>
            <a-select-option value="2">未通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-item>
      </a-form>
      <a-table
        :scroll="{x: 1200, y: tableScrollY }"
        v-auth="'user_manage_audit_list'"
        :rowKey="record => record.id"
        :columns="columns"
        :dataSource="list"
        :pagination="pagination"
        :loading="listLoading"
        :locale="{ emptyText: '暂无申请用户' }"
        @change="handleTableChange"
      >
        <!-- 渠道插槽 -->
      <!--  <template slot="channel_tag" slot-scope="record">
          <a-tag v-if="record.channel===2" color="orange">淘宝</a-tag>
          <a-tag v-if="record.channel===1" color="purple">抖音</a-tag>
        </template>-->
        <!-- 申请列表操作插槽 -->
        <template slot="operation" slot-scope="record">
          <a-button
            type="primary"
            size="small"
            @click="handleAudioClick(record)"
            v-auth="'user_manage_audit_user'"
          >审核</a-button>
        </template>
        <!-- 审核记录插槽 -->
        <template slot="status" slot-scope="record">
          <span
            :class="{ 'passed': USER_STATUS.PASSED.VALUE == record.status, 'not-pass': USER_STATUS.NOT_PASS.VALUE == record.status }"
          >{{getStatusText(record.status)}}</span>
        </template>
        <template slot="record-operation" slot-scope="record">
          <a-button type="primary" size="small" @click="handleViewClick(record)">查看</a-button>
        </template>
      </a-table>
    </div>
    <!-- 审核弹窗 -->
    <a-modal class="audit-dialog" title="注册审核" v-model="auditDialogVisible" centered :footer="null">
   <!--   <a-form-item label="渠道" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <div v-if="auditingData && auditingData.channel ===2">淘宝</div>
        <div v-if="auditingData && auditingData.channel ===1">抖音</div>
      </a-form-item>-->
      <a-form-item
        label="手机号"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{auditingData && auditingData.mobile || ''}}</a-form-item>

      <!-- 抖音 -->
      <a-form-item
        v-if="auditingData && auditingData.channel ===1"
        label="抖音号"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{auditingData && auditingData.douyin_account || ''}}</a-form-item>
      <!-- 淘宝 -->
      <template v-if="auditingData && auditingData.channel ===2">
        <a-form-item
          label="主播昵称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{auditingData.anchor_name}}</a-form-item>
        <a-form-item label="图片凭证" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-row v-if="auditingData.taobao_cert.length > 0" :gutter="5" id="cert-image-wrap">
            <a-col v-for="(cert, index) in auditingData.taobao_cert" :key="index" :span="6">
              <img
                class="cert-image"
                :data-original="cert"
                :src="cert + '?x-oss-process=image/resize,m_fill,h_140,w_140'"
              />
            </a-col>
          </a-row>
        </a-form-item>
      </template>

      <a-form-item
        label="申请时间"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{auditingData && auditingData.create_time || ''}}</a-form-item>
      <a-divider></a-divider>
      <h2>审核结果</h2>
      <a-form :form="auditForm" @submit="handleAuditFormSubmit">
        <a-form-item>
          <a-radio-group v-model="status" @change="handleRadioChange">
            <a-radio :value="USER_STATUS.PASSED.VALUE">通过</a-radio>
            <a-radio :value="USER_STATUS.NOT_PASS.VALUE">拒绝</a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-if="status===USER_STATUS.PASSED.VALUE">
          <!-- 抖音 -->
          <template v-if="auditingData && auditingData.channel === 1">
            <a-form-item label="淘宝客PID" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-input
                v-decorator="[
                'pid',
                {
                  rules: [
                    { validator: checkTbkPid },
                  ],
                }
              ]"
                placeholder="请输入淘宝客PID"
              ></a-input>
            </a-form-item>
            <a-form-item label="抖音昵称" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-input v-decorator="[ 'douyin_nickname' ]" placeholder="请输入抖音昵称"></a-input>
            </a-form-item>
          </template>
          <!-- 公用 -->
          <a-form-item label="微信号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-input v-decorator="[ 'wx_account' ]" placeholder="请输入微信号"></a-input>
          </a-form-item>
        </div>
        <div v-if="status===USER_STATUS.NOT_PASS.VALUE">
          <a-form-item>
            <a-textarea
              v-decorator="[
              'fail_reasons',
              {
                rules: [
                  { required: true, message: '请填写拒绝原因' },
                  { validator: checkFailReasons }
                ]
              }
            ]"
              placeholder="请填写拒绝原因，拒绝原因会通过短信通知用户"
              :autosize="{ minRows: 2, maxRows: 6 }"
            />
          </a-form-item>
        </div>
        <a-form-item>
          <div class="footer-btns">
            <a-button @click="auditDialogVisible = false">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 审核二次确认弹窗 -->
    <a-modal
      :title="auditStatus===USER_STATUS.PASSED.VALUE ? '审核通过' : '审核拒绝'"
      :width="320"
      v-model="confirmDialogVisiable"
      wrapClassName="confirm-modal"
      @cancel="confirmDialogVisiable = false"
      centered
    >
      <div class="approval-modal-content">
        <p>{{auditStatus===USER_STATUS.PASSED.VALUE ? '确定要通过该用户的注册申请？' : '确定要拒绝该用户的注册申请？'}}</p>
      </div>
      <template slot="footer" class="footer-btns">
        <a-button @click="confirmDialogVisiable = false">取消</a-button>
        <a-button type="primary" :loading="posting" @click="handleModalOkClick">确认</a-button>
      </template>
    </a-modal>
    <!-- 审核记录弹窗 -->
    <a-modal
      title="注册审核"
      class="audit-dialog"
      v-model="reviewDialogVisiable"
      centered
      :footer="null"
    >
      <a-form v-if="reviewDetail">
      <!--  <a-form-item label="渠道" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <div v-if="reviewDetail && reviewDetail.channel ===2">淘宝</div>
          <div v-if="reviewDetail && reviewDetail.channel ===1">抖音</div>
        </a-form-item>-->
        <a-form-item
          label="手机号"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{reviewDetail.mobile}}</a-form-item>

        <!-- 抖音 -->
        <a-form-item
          v-if="reviewDetail && reviewDetail.channel === 1"
          label="抖音号"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{reviewDetail.douyin_account}}</a-form-item>
        <!-- 淘宝 -->
        <template v-if="reviewDetail && reviewDetail.channel === 2">
          <a-form-item
            label="主播昵称"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.anchor_name}}</a-form-item>
        </template>

        <a-form-item
          label="申请时间"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{reviewDetail.create_time}}</a-form-item>
        <a-divider></a-divider>
        <h2>
          审核结果
          <span
            :class="[
            'status-text',
            { 'success-status': reviewDetail.status === USER_STATUS.PASSED.VALUE },
            { 'fail-status': reviewDetail.status === USER_STATUS.NOT_PASS.VALUE }
          ]"
          >{{getStatusText(reviewDetail.status)}}</span>
        </h2>
        <template v-if="reviewDetail.status === USER_STATUS.PASSED.VALUE">
          <a-form-item
            label="淘宝客PID"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
            v-if="reviewDetail.channel === 1"
          >{{reviewDetail.pid}}</a-form-item>
          <a-form-item
            v-if="reviewDetail && reviewDetail.channel === 1"
            label="抖音昵称"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.douyin_nickname}}</a-form-item>
          <a-form-item
            label="微信号"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.wx_account}}</a-form-item>
          <a-form-item
            label="审核人"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.audit_user__name}}</a-form-item>
          <a-form-item
            label="审核时间"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.audit_time}}</a-form-item>
        </template>
        <template v-else>
          <a-form-item
            label="拒绝原因"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.fail_reasons}}</a-form-item>
          <a-form-item
            label="审核人"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.audit_user__name}}</a-form-item>
          <a-form-item
            label="审核时间"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >{{reviewDetail.audit_time}}</a-form-item>
        </template>
        <a-form-item>
          <div class="footer-btns">
            <a-button type="primary" @click="reviewDialogVisiable = false">关闭</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// 审核中 表头
const firstColumns = [
/*  {
    title: "渠道",
    align: "center",
    scopedSlots: { customRender: "channel_tag" }
  },*/
  {
    title: "手机号",
    dataIndex: "mobile",
    align: "center",
  },
  // {
  //   title: '抖音号',
  //   dataIndex: 'douyin_account',
  //   align: 'center'
  // },
  {
    title: "申请时间",
    dataIndex: "create_time",
    align: "center"
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
];
// 审核记录 表头
const secondColums = [
  /*{
    title: "渠道",
    align: "center",
    scopedSlots: { customRender: "channel_tag" }
  },*/
  {
    title: "手机号",
    dataIndex: "mobile",
    align: "center"
  },
  // {
  //   title: '抖音号',
  //   dataIndex: 'douyin_account',
  //   align: 'center'
  // },
  {
    title: "审核结果",
    key: "status",
    scopedSlots: { customRender: "status" },
    align: "center"
  },
  {
    title: "审核人",
    dataIndex: "audit_user__name",
    align: "center"
  },
  {
    title: "审核时间",
    dataIndex: "update_time",
    align: "center"
  },
  {
    title: "申请时间",
    dataIndex: "create_time",
    align: "center"
  },
  // {
  //   title: '淘宝客PID',
  //   dataIndex: 'pid',
  //   align: 'center'
  // },
  // {
  //   title: '拒绝原因',
  //   dataIndex: 'fail_reasons',
  //   align: 'center'
  // },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "record-operation" }
  }
];

import { getUserList, auditUser, getTaobaoFans } from "../../../service/user";
import { USER_STATUS } from "../../../const/index";
import { checkTbkPid } from "../../../utils/validator";
import { checkPer } from "@/utils/index";
import Viewer from "viewerjs";

export default {
  name: "audit",
  data() {
    return {
      tableScrollY: 600,
      USER_STATUS,
      checkTbkPid,
      form: this.$form.createForm(this),
      firstColumns,
      secondColums,
      columns: null,
      postingTb: false,
      isFirstLoad: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      listLoading: false,
      // 审核
      auditDialogVisible: false,
      auditForm: this.$form.createForm(this),
      currentTab: "1",
      // 二次确认弹窗显示标志
      confirmDialogVisiable: false,
      // 当前审核中的数据
      auditingData: null,
      // 审核拒绝
      auditStatus: USER_STATUS.PASSED.VALUE,
      status: USER_STATUS.PASSED.VALUE,
      // 审核记录弹窗显示标志
      reviewDialogVisiable: false,
      reviewDetail: null,
      posting: false,
      searchParams: {},
      pid: null
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 370;
  },
  mounted() {
    this.getAuditList();
  },
  methods: {
    // 重新查询
    async getNewTaobao() {
      this.postingTb = true;
      const data = { audit_id: this.auditingData.id };
      const { err, res } = await getTaobaoFans(data);
      this.postingTb = false;
      if (!err) {
        const _data = res.data;
          this.auditingData.anchor_name_from_yihoc = _data.anchor_name_from_yihoc ? _data.anchor_name_from_yihoc : null;
          this.auditingData.fans_count = _data.fans_count ? _data.fans_count : null;
          this.auditingData.rank = _data.rank ? _data.rank : null;
          this.auditingData.areas =  _data.areas && _data.areas.length
            ? _data.areas.join(",")
            : "";
        if (res.success) {
          this.auditingData.update_time = _data.update_time ? _data.update_time : null;
          this.$message.success("查询成功，结果已更新");
        }
      }
    },
    // tab切换回调
    tabChangeCallback(e) {
      this.currentTab = e;
      this.pagination.current = 1;
      this.searchParams.search = "";
      // this.searchParams.channel = null;
      this.searchParams.status = null;
      this.form.resetFields();
      this.getAuditList();
    },
    // 申请列表搜素表单submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          this.pagination.current = 1;
          this.searchParams.search = value.mdtz;
          // this.searchParams.channel = value.channel;
          this.searchParams.status = value.status;
          this.getAuditList();
        }
      });
    },
    // 获取申请列表
    async getAuditList() {
      if (!checkPer("user_manage_audit_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        search: this.searchParams.search,
        // channel: this.searchParams.channel,
        status: this.searchParams.status
      };
      if (this.currentTab === "1") {
        this.columns = this.firstColumns;
        data.status = USER_STATUS.UNDER_REVIEW.VALUE;
        data.ordering = "-create_time";
      } else if (this.currentTab === "2") {
        this.columns = this.secondColums;
        data.status__gte = "1";
        data.ordering = "-audit_time";
      }
      this.listLoading = true;
      const { err, res } = await getUserList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getAuditList();
    },
    // 点击审核按钮
    handleAudioClick(record) {
      this.auditForm.resetFields();
      this.auditingData = JSON.parse(JSON.stringify(record));
      this.auditStatus = USER_STATUS.PASSED.VALUE;
      this.status = USER_STATUS.PASSED.VALUE;
      this.auditDialogVisible = true;
      if (this.auditingData.taobao_cert.length) {
        this.$nextTick(() => {
          // 初始化 相册功能
          new Viewer(document.getElementById("cert-image-wrap"), {
            url: "data-original"
          });
        });
      }
    },
    // 审核form提交按钮点击
    handleAuditFormSubmit(e) {
      e.preventDefault();
      this.auditForm.validateFields(async formError => {
        if (!formError) {
          // 显示二次确认弹窗
          this.confirmDialogVisiable = true;
        }
      });
    },
    // 二次确认弹窗确定按钮点击，发起审核请求
    handleModalOkClick() {
      this.auditForm.validateFields(async (formError, values) => {
        if (!formError) {
          this.posting = true;
          values.status = this.status;
          values.pid = values.pid ? values.pid : undefined;
          const { err, res } = await auditUser(this.auditingData.id, values);
          this.posting = false;
          if (!err && res.success) {
            this.$message.success(res.message);
            this.auditDialogVisible = false;
            this.confirmDialogVisiable = false;
            this.auditingData = null;
            this.getAuditList();
          }
        }
      });
    },
    // 获取状态中文
    getStatusText(status) {
      let statusName = "";
      for (let key in USER_STATUS) {
        if (USER_STATUS[key].VALUE == status) {
          statusName = USER_STATUS[key].TEXT;
          break;
        }
      }
      return statusName;
    },
    // 切换审核记过单选
    handleRadioChange(e) {
      this.auditStatus = e.target.value;
    },
    // 搜索框数据发生改变
    handleFormInputChange(e) {
      if (e.target.value === "") this.getAuditList();
    },
    /**
     * pid检测方法
     * 在公共的检测方法之外再套一层逻辑，为了在“拒绝”状态下不检测pid
     */
    customCheckTbkPid(rule, value, cb) {
      if (
        this.auditForm.getFieldValue("status") === USER_STATUS.NOT_PASS.VALUE
      ) {
        return cb();
      }
      checkTbkPid(rule, value, cb);
    },
    // 检测拒绝原因
    checkFailReasons(rule, value, cb) {
      if (value == "") {
        cb("请输入拒绝原因");
      } else {
        cb();
      }
    },
    // 点击审核记录查看按钮
    handleViewClick(record) {
      this.reviewDetail = record;
      this.reviewDialogVisiable = true;
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../../assets/less/var.less";
#audit {
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    padding-bottom: 2px;
  }
  .filter-condition-form {
    height: 60px;
    .keyword-input {
      width: 300px;
    }
  }
  .passed {
    color: @green-color;
  }
  .not-pass {
    color: @red-color;
  }
  .fail-reasons-icon {
    margin-left: 5px;
    color: @red-color;
  }
}
</style>
<style lang='less'>
@import "../../../assets/less/var.less";
.tab-content {
  /deep/ .ant-table-body {
    //样式穿透
    overflow-x: auto !important;
  }
}
.audit-dialog {
  .ant-form-item {
    margin: 0;
  }
  .ant-modal-header {
    text-align: center;
  }
  .footer-btns {
    margin-top: 20px;
    text-align: center;
    button {
      margin: 0 10px;
    }
  }
  .status-text {
    font-size: 16px;
    margin-left: 15px;
    &.success-status {
      color: @green-color;
    }
    &.fail-status {
      color: @red-color;
    }
  }
  .cert-image {
    width: 100%;
  }
}
.confirm-modal {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    text-align: center;
  }
  .approval-modal-content {
    text-align: center;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
  }
}
.flex-t {
  display: flex;
  justify-content: space-between;
}
.mr-20 {
  margin-right: 20px;
}
</style>
