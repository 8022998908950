/*
 * @Description: 公用 表单自定义验证规则
 * @Date: 2020-01-04 11:03:26
 * @LastEditTime: 2020-07-16 14:34:03
 * @LastEditors: 前胡
 */

// 手机号验证
export const checkPhoneNumber = (rule, value, cb) => {
  if (value === '' || value === undefined || value === null) {
    cb()
  } else {
  if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
    cb('手机号格式有误')
  } else {
    cb()
  }
}
}

// 纯数字
export const checkNumber = (rule, value, cb) => {
  if (value === '' || value === undefined || value === null) {
    cb()
  } else {
    if (!(/^[0-9]*$/.test(value))) {
      cb('只能为全数字')
    } else {
      cb()
    }
  }
}

// 数值大于0
export const checkNumberOverZero = (rule, value, cb) => {
  if (value === '' || value === undefined || value === null) {
    cb()
  } else {
    if (value <= 0) {
      cb('数值必须大于0')
    } else {
      cb()
    }
  }
}

// 不能为零开头
export const checkIsNotZero = (rule, value, cb) => {
  if (value === '' || value === undefined || value === null) {
    cb()
  } else {
    if ((/^[0]/.test(value))) {
      cb('数值不能为零开头')
    } else {
      cb()
    }
  }
}

// pid检测方法
export const checkTbkPid = (rule, value, cb) => {
  if (value === '' || value === undefined || value === null) {
    cb()
  } else {
    if (!(/^mm_\d+_\d+_\d+$/.test(value))) {
      cb('pid格式有误')
    } else {
      cb()
    }
  }
}

// 快递单号验证
export const checkExpressNo = (rule, value, cb) => {
  if (!(/^[A-Za-z0-9]+$/.test(value))) {
    cb('快递单号格式有误')
  } else {
    cb()
  }
}

// 不能输入中文
export const checkNotChinese = (rule, value, cb) => {
  if (value === '' || value === undefined) {
    cb()
  } else {
    if (!(/^[A-Za-z0-9_.]+$/.test(value))) {
      cb('只允许字母、数字、下划线、点')
    } else {
      cb()
    }
  }
}
// 不能输入特殊字符
export const checkNotspecial = (rule, value, cb) => {
  if (value === '' || value === undefined) {
    cb()
  } else {
    ////[^\u4E00-\u9FA5]/g
    ///[^\a-\z\A-\Z0-9\u4E00-\u9FA5\@\.]/g
    if (!(/^[\u4E00-\u9FA5A-Za-z0-9.]+$/.test(value))) {
      cb('只允许字母|数字|中文')
    }else if(value.length===1||value.length>6) {
      cb('限制输入2-6')
    } else {
      cb()
    }
  }
}
// 不能输入中文,只允许字母、数字
export const checkNotChineseandnoelse = (rule, value, cb) => {
  if (value === '' || value === undefined) {
    cb()
  } else {
    if (!(/^[A-Za-z0-9]+$/.test(value))) {
      cb('只允许字母、数字')
    } else if(value.length>12) {
      cb('最多输入12位')
     } else {
    cb()
  }
  }
}
// 不能纯40位数字
export const checkDigest40 = (rule, value, cb) => {
  if ((/^\d{40}$/.test(value))) {
    cb('不能纯40位数字')
  } else {
    cb()
  }
}

// url验证
export const checkUrl = (rule, value, cb) => {
  if (!(/(http|https):\/\/([\w.]+\/?)\S*/.test(value))) {
    cb('必须以http,https开头,且需符合URL规范,检查格式是否正确')
  } else {
    cb()
  }
}

// 去掉判断是否为全空格
export const checkBlank = (rule, value, cb) => {
  if (value === '' || value === undefined) {
    cb()
  } else {
    const _s = value.replace(/\s+/g, '');
    if (_s.length === 0) {
      cb('不能全部为空格')
    } else {
      cb()
    }
  }
  // const _s = value.replace(/\s+/g, '');
  // if (_s.length === 0) {
  //   cb('不能全部为空格')
  // } else {
  //   cb()
  // }
}
